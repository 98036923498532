const URL = process.env.GATSBY_SANITY_GRAPHQL;
export async function sanityClient(query) {
  const options = {
    endpoint: URL,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ query }),
  };

  try {
    // eslint-disable-next-line no-undef
    const response = await fetch(URL, options).then(res => res.json());
    return response;
  } catch (error) {
    console.log(error);
    throw new Error('There was an error fetching the data from Server. Please try again later.');
  }
}
