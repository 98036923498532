import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import axios from 'axios';
import { validateEmail } from '../../../utils/generalFun';
import styles from './paymentDialog.module.scss';

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      // iconColor: '#c4f0ff',
      color: '#000',
      fontFamily: 'FoundersGrotesk-Light',
      fontSize: 14,
      lineHeight: '22px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#777',
      },
    },
    invalid: {
      iconColor: '#9e2146',
      color: '#9e2146',
    },
  },
};

export default function PaymentDialog({ closeDialog, productDetail, payMethod }) {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] = useState(payMethod);
  const [showConfirmationNotice, setShowConfirmationNotice] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [payIntent, setPaymentIntent] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [showError, setShowError] = useState(false);

  const inputValidation = () => {
    setEmailError(false);
    setNameError(false);
    setPhoneError(false);
    if (!email || !validateEmail(email)) {
      setEmailError(true);
      return false;
    }
    if (!name || !name.match(/^[a-zA-Z][a-zA-Z ]+$/)) {
      setNameError(true);
      return false;
    }
    if (!phone || !phone.match(/^[0-9]+$/)) {
      setPhoneError(true);
      return false;
    }
    return true;
  };

  // proceed ACH Direct Debit
  const proceedACH = () => {
    if (inputValidation()) {
      setLoading(true);
      axios
        .get(`${process.env.GATSBY_API_URL}/payment/create-payment-intent/bank`, {
          params: {
            price: productDetail?.price,
          },
        })
        .then(response => {
          if (response?.data?.clientSecret) {
            setClientSecret(response?.data?.clientSecret);

            stripe
              .collectBankAccountForPayment({
                clientSecret: response?.data?.clientSecret,
                params: {
                  payment_method_type: 'us_bank_account',
                  payment_method_data: {
                    billing_details: {
                      name: name,
                      email: email.toLowerCase().trim(),
                    },
                  },
                },
                expand: ['payment_method'],
              })
              .then(({ paymentIntent, error }) => {
                setLoading(false);
                if (error) {
                  toast.error(error.message);
                  // PaymentMethod collection failed for some reason.
                } else if (paymentIntent.status === 'requires_payment_method') {
                  // Customer canceled the hosted verification modal. Present them with other
                  // payment method type options.
                } else if (paymentIntent.status === 'requires_confirmation') {
                  // We collected an account - possibly instantly verified, but possibly
                  // manually-entered. Display payment method details and mandate text
                  // to the customer and confirm the intent once they accept
                  // the mandate.
                  setPaymentIntent(paymentIntent);
                  setShowConfirmationNotice(true);
                }
              });
          } else {
            toast.error('Something went wrong!');
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error('Something went wrong!');
        });
    }
  };

  const confirmACHPayment = () => {
    setLoading(true);
    stripe.confirmUsBankAccountPayment(clientSecret).then(({ paymentIntent, error }) => {
      if (error) {
        setLoading(false);
        toast.error(error.message);
        // The payment failed for some reason.
      } else if (paymentIntent.status === 'requires_payment_method') {
        // Confirmation failed. Attempt again with a different payment method.
        setLoading(false);
        setShowConfirmationNotice(false);
        toast.error('Confirmation failed. Attempt again with a different payment method.');
      } else if (paymentIntent.status === 'processing') {
        // Confirmation succeeded! The account will be debited.
        // Display a message to customer.
        submitOrder('ach');
      }
      // else if (paymentIntent.next_action?.type === 'verify_with_microdeposits') {
      //   // The account needs to be verified via microdeposits.
      //   // Display a message to consumer with next steps (consumer waits for
      //   // microdeposits, then enters a statement descriptor code on a page sent to them via email).
      //   console.log('Micro Deposits!');
      // }
    });
  };

  // handle card purchase
  const handleCardProcessing = async () => {
    if (inputValidation()) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      if (error) {
        elements.getElement('card').focus();
        return;
      }
      const billingDetails = {
        email: email.toLowerCase().trim(),
      };
      if (cardComplete) {
        setLoading(true);
      } else {
        toast.error('Card detail required!');
        return;
      }

      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        try {
          const { data } = await axios.get(
            `${process.env.GATSBY_API_URL}/payment/create-payment-intent/card`,
            {
              params: {
                price: productDetail?.price + productDetail?.processingFee,
              },
            }
          );

          const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: billingDetails,
            // description: tractorReserveData?.tractorName,
          });

          if (payload.error) {
            toast(payload?.error?.message);
            setLoading(false);
            return;
          }

          const { error } = await stripe.confirmCardPayment(data.clientSecret, {
            payment_method: payload.paymentMethod.id,
          });

          if (error) {
            toast.error('Payment method failed, please try again');
            setLoading(false);
            return;
          } else {
            // toast.success('Payment successful!');
            submitOrder('card');
          }
        } catch (err) {
          toast.error('Something went wrong! please try again');
          // setError(err.message || "");
          setLoading(false);
        }
      }
    }
  };

  const submitOrder = methodUsed => {
    let data = {
      productTitle: productDetail?.title,
      productDetail: productDetail?.detail,
      productPrice: productDetail?.price,
      amountCharged:
        methodUsed === 'ach'
          ? productDetail?.price
          : productDetail?.price + productDetail?.processingFee,
      customerName: name,
      customerEmail: email.toLowerCase().trim(),
      customerPhone: phone,
      paymentStatus: methodUsed === 'ach' ? 'processing' : 'completed',
      paymentMethod: methodUsed,
      paymentMethodTitle:
        methodUsed === 'ach'
          ? payIntent?.payment_method?.us_bank_account?.bank_name
          : 'Credit Card',
      paymentIntentID: methodUsed === 'ach' ? payIntent?.id : '',
    };
    axios
      .post(`${process.env.GATSBY_API_URL}/order`, data)
      .then(() => {
        setLoading(false);
        setShowConfirmationNotice(false);
        setShowSuccess(true);
      })
      .catch(() => {
        setShowError(true);
      });
  };

  return (
    <div className={`${styles.dialog}`}>
      <button className={styles.closeBtn} onClick={closeDialog}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
          <path
            fillRule='evenodd'
            d='M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z'
            clipRule='evenodd'
          />
        </svg>
      </button>
      {!showConfirmationNotice && !showSuccess && !showError && (
        <div className={styles.form__wrapper}>
          <h1 className={styles.__title}>Payment Details</h1>
          <div className={styles.payment__methods}>
            <button
              className={`${styles.pm__chooser} ${paymentMethod === 'card' && styles.active}`}
              onClick={() => setPaymentMethod('card')}>
              <div>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
                  <path d='M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z' />
                  <path
                    fillRule='evenodd'
                    d='M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z'
                    clipRule='evenodd'
                  />
                </svg>{' '}
                <span>Credit / Debit Card</span>
              </div>
              {paymentMethod === 'card' && (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#708863'>
                  <path
                    fillRule='evenodd'
                    d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
            </button>
            <button
              className={`${styles.pm__chooser} ${paymentMethod === 'ach' && styles.active}`}
              onClick={() => setPaymentMethod('ach')}>
              <div>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
                  <path d='M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z' />
                  <path
                    fillRule='evenodd'
                    d='M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z'
                    clipRule='evenodd'
                  />
                  <path d='M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z' />
                </svg>
                <span>ACH Direct Debit</span>
              </div>
              {paymentMethod === 'ach' && (
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#708863'>
                  <path
                    fillRule='evenodd'
                    d='M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
            </button>
          </div>
          <form className={styles.form}>
            <input
              type='email'
              placeholder='Email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              className={`${emailError && styles.error}`}
            />
            <input
              type='text'
              placeholder='Full Name'
              value={name}
              onChange={e => setName(e.target.value)}
              className={`${nameError && styles.error}`}
            />
            <input
              type='text'
              placeholder='Phone'
              value={phone}
              onChange={e => setPhone(e.target.value)}
              className={`${phoneError && styles.error}`}
            />
            {paymentMethod === 'card' && (
              <div>
                <div className={`${styles.card_wrapper} ${error && styles.error}`}>
                  <CardElement
                    options={CARD_OPTIONS}
                    onChange={e => {
                      setError(e.error || '');
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
                <hr />
                <div className={styles.pay_detail}>
                  <span>
                    This is a 50% deposit to guarantee your spot. The remaining 50% payment will be
                    due by January 31st 2023.
                  </span>
                </div>
                <div className={styles.pay_detail}>
                  <span>Subtotal</span>
                  <span>$ {productDetail?.price}</span>
                </div>
                <div className={styles.pay_detail}>
                  <span>Processing Fee</span>
                  <span>3%</span>
                </div>
                <div className={styles.pay_detail}>
                  <span>Due Now</span>
                  <span>$ {productDetail?.price + productDetail?.processingFee}</span>
                </div>
                <button
                  type='button'
                  disabled={loading}
                  className={styles.checkout_btn}
                  onClick={handleCardProcessing}>
                  {loading ? <div className={styles.loader}></div> : <>Checkout</>}
                </button>
              </div>
            )}
            {paymentMethod === 'ach' && (
              <div>
                <hr />
                <div className={styles.pay_detail}>
                  <span>
                    This is a 50% deposit to guarantee your spot. The remaining 50% payment will be
                    due by January 31st 2023.
                  </span>
                </div>
                <div className={styles.pay_detail}>
                  <span>Subtotal</span>
                  <span>$ {productDetail?.price}</span>
                </div>
                <div className={styles.pay_detail}>
                  <span>Due Now</span>
                  <span>$ {productDetail?.price}</span>
                </div>

                <button
                  disabled={loading}
                  type='button'
                  className={styles.checkout_btn}
                  onClick={proceedACH}>
                  {loading ? <div className={styles.loader}></div> : <>Checkout</>}
                </button>
              </div>
            )}
          </form>
        </div>
      )}
      {showConfirmationNotice && (
        <div className={styles.form__wrapper}>
          <h4 className={styles.__title}>Direct Debit Confirmation</h4>
          <div className={styles.account__details}>
            <div className={styles.account__row}>
              <div className={styles.col_left}>Email</div>
              <div className={styles.col_right}>{email}</div>
            </div>
            <div className={styles.account__row}>
              <div className={styles.col_left}>Name</div>
              <div className={styles.col_right}>{name}</div>
            </div>
            <div className={styles.account__row}>
              <div className={styles.col_left}>Phone</div>
              <div className={styles.col_right}>{phone}</div>
            </div>
            <div className={styles.account__row}>
              <div className={styles.col_left}>Bank</div>
              <div className={styles.col_right}>
                {payIntent?.payment_method?.us_bank_account?.bank_name}
              </div>
            </div>
            <div className={styles.account__row}>
              <div className={styles.col_left}>Routing#</div>
              <div className={styles.col_right}>
                {payIntent?.payment_method?.us_bank_account?.routing_number}
              </div>
            </div>
          </div>
          <div className={styles.mandate}>
            By clicking Confirm, you authorize ROANDCO to debit the bank account specified above for
            any amount owed for charges arising from your use of ROANDCO’ services and/or purchase
            of products from ROANDCO, pursuant to ROANDCO’ website and terms, until this
            authorization is revoked. You may amend or cancel this authorization at any time by
            providing notice to ROANDCO with 30 (thirty) days notice.
          </div>
          <button
            disabled={loading}
            type='button'
            className={styles.checkout_btn}
            onClick={confirmACHPayment}>
            {loading ? <div className={styles.loader}></div> : <>Confirm</>}
          </button>
        </div>
      )}
      {showSuccess && (
        <div className={styles.form__wrapper}>
          <h1 className={styles.__title} style={{ textAlign: 'center' }}>
            Thank you for Deposit!
          </h1>
        </div>
      )}
      {showError && (
        <div className={styles.form__wrapper}>
          <h1 className={styles.__title} style={{ textAlign: 'center' }}>
            Somwthing went wrong! Contact at info@roandco.com
          </h1>
        </div>
      )}
    </div>
  );
}
