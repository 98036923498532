import React from 'react';
import styles from './pricing-policy.module.scss';

export default function PricingPolicy({ content }) {
  return (
    <section>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.grid}>
            <div className={styles.col}>
              <div className={styles.title}>
                <h1>{content?.title}</h1>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.detail}>
                {content?.qa.map((item, index) => (
                  <div className={styles.part} key={index}>
                    <h2>{item?.title}</h2>
                    <p>{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
