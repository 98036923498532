import React from 'react';
import styles from './footer.module.scss';

export default function Footer({ content }) {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__links}>
        {content?.link?.map((item, index) => (
          <a href={item?.url} className={styles.link} key={index}>
            {item.title}
          </a>
        ))}
      </div>
      <div className={styles.footer__cb}>
        <div>Created by</div>
        <div className={styles.__desk}>{content?.createdBy}</div>
        <div className={styles.__mobile}>{content?.createdBy}</div>
      </div>
      <div className={styles.__desk}>
        <div className={`${styles.footer__cb} ${styles.cb__logos}`}>
          {content?.createdByLogos?.map((item, index) => (
            <a href={item?.url} key={index}>
              <div
                className={`${index % 2 === 0 ? styles.logo__mr : styles.logo__ml}`}
                dangerouslySetInnerHTML={{ __html: item?.logo }}></div>
            </a>
          ))}
        </div>
      </div>
      <div className={styles.__mobile}>
        <div className={`${styles.footer__cb} ${styles.cb__logos}`}>
          {content?.createdByLogosM?.map((item, index) => (
            <a href={item?.url} key={index}>
              <div
                className={`${index !== content?.createdByLogosM.length - 1 && styles.mb__logo}`}
                dangerouslySetInnerHTML={{ __html: item?.logo }}></div>
            </a>
          ))}
        </div>
      </div>
      <div className={`${styles.site_logo__container} ${styles.__desk}`}>
        <div dangerouslySetInnerHTML={{ __html: content?.footerLogoD }}></div>
      </div>
      <div className={`${styles.site_logo__container} ${styles.__tablet}`}>
        <div dangerouslySetInnerHTML={{ __html: content?.footerLogoT }}></div>
      </div>
      <div className={`${styles.site_logo__container} ${styles.__mobile}`}>
        <div dangerouslySetInnerHTML={{ __html: content?.footerLogoM }}></div>
      </div>
    </footer>
  );
}
