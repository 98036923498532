import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { sanityClient } from '../lib/client';
import { Toaster } from 'react-hot-toast';
import Hero from '../components/ofTwoMinds/hero';
import PricingPolicy from '../components/ofTwoMinds/pricingPolicy';
import Products from '../components/ofTwoMinds/products';
import PaymentDialog from '../components/ofTwoMinds/paymentDialog';
import Footer from '../components/ofTwoMinds/footer';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

export default function OffTwoMinds({ data: { sanityOffTwoMinds } }) {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [productDetail, setProductDetail] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [productsData, setProductsData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const query = `query{
      OffTwoMinds(id:"offTwoMinds"){
        productsSection {
            title
            detail
            products {
              title
              soldOut
              detail
              images {
                asset {
                  url
                }
              }
              price
              pricePlaceholder
              processingFee
            }
          }
      }
    }`;
      const response = await sanityClient(query);
      if (response?.data?.OffTwoMinds) {
        setProductsData(response?.data?.OffTwoMinds);
      }
      setLoading(false);
    }
    fetchData();
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width,  minimum-scale=1, initial-scale=1, shrink-to-fit=no'
        />
      </Helmet>

      <div className='otm'>
        <Hero content={sanityOffTwoMinds?.heroSection} />
        <section>
          <div className='otm_desc'>
            <div className='otm_container'>
              <p>{sanityOffTwoMinds?.description}</p>
            </div>
          </div>
        </section>
        {/* pricing section */}
        <PricingPolicy content={sanityOffTwoMinds?.pricingSection} />
        <Products
          showDialog={() => setShowPaymentDialog(true)}
          content={productsData?.productsSection}
          setProductDetail={setProductDetail}
          setPaymentMethod={setPaymentMethod}
          loading={loading}
        />
        {/* policy section */}
        <PricingPolicy content={sanityOffTwoMinds?.policySection} />
        <Footer content={sanityOffTwoMinds?.footer} />
        {showPaymentDialog && (
          <PaymentDialog
            closeDialog={() => setShowPaymentDialog(false)}
            productDetail={productDetail}
            payMethod={paymentMethod}
          />
        )}
        <Toaster />
      </div>
    </Elements>
  );
}

export const query = graphql`
  query OfTwoMindsQuery {
    sanityOffTwoMinds {
      heroSection {
        heroBG {
          asset {
            url
          }
        }
        heroLogoD
        heroLogoM
        heroLogoT
      }
      description
      pricingSection {
        title
        qa {
          title
          description
        }
      }
      productsSection {
        title
        detail
        products {
          title
          soldOut
          detail
          images {
            asset {
              url
            }
          }
          price
          pricePlaceholder
          processingFee
        }
      }
      policySection {
        title
        qa {
          title
          description
        }
      }
      footer {
        link {
          title
          url
        }
        createdBy
        createdByMobile
        createdByLogos {
          logo
          url
        }
        createdByLogosM {
          logo
          url
        }
        footerLogoD
        footerLogoT
        footerLogoM
      }
    }
  }
`;
