import React, { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './products.module.scss';

const Products = ({ showDialog, content, setProductDetail, setPaymentMethod, loading }) => {
  const handleClick = (title, detail, price, processingFee, method) => {
    setProductDetail({
      title,
      detail,
      price,
      processingFee,
    });
    setPaymentMethod(method);
    showDialog();
  };
  return (
    <section>
      <div className={styles.products}>
        {!loading ? (
          <div className={styles.container}>
            {!content ? (
              <div className={styles.errorMsg}>
                There was an error fetching the products from Server!
              </div>
            ) : (
              <Fragment>
                <div className={styles.__top}>
                  <div className={styles.grid}>
                    <div className={styles.col}>
                      <div className={styles.title}>
                        <h1>{content?.title}</h1>
                      </div>
                    </div>
                    <div className={styles.col}>
                      <div className={styles.detail}>
                        <div className={styles.part}>
                          <p>{content?.detail}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {content?.products?.length > 0 && (
                  <div className={styles.product__list}>
                    {content?.products?.map((item, i) => (
                      <div className={styles.__product} key={i}>
                        <div className={`${i === 1 ? styles.__gallery__h : styles.__gallery}`}>
                          <Swiper id={`swiper-${i}`} slidesPerView={1} loop>
                            {item?.images.map(image => (
                              <SwiperSlide key={image?.asset?.url}>
                                <div className={styles.img}>
                                  <img src={image?.asset?.url} />
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                        <div className={styles.__arrows}>
                          <button
                            className={styles.anim_left}
                            onClick={() => {
                              const swiper = document.getElementById(`swiper-${i}`).swiper;
                              swiper.slidePrev();
                            }}>
                            <svg
                              width='15'
                              height='12'
                              viewBox='0 0 15 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <line
                                y1='6.14999'
                                x2='15'
                                y2='6.14999'
                                stroke='black'
                                strokeWidth='0.5'
                              />
                              <line
                                y1='-0.25'
                                x2='7.39947'
                                y2='-0.25'
                                transform='matrix(-0.675725 0.737154 -0.675725 -0.737154 5 0)'
                                stroke='black'
                                strokeWidth='0.5'
                              />
                              <line
                                y1='-0.25'
                                x2='7.39946'
                                y2='-0.25'
                                transform='matrix(0.675725 0.737154 -0.675725 0.737154 0 6.54547)'
                                stroke='black'
                                strokeWidth='0.5'
                              />
                            </svg>
                          </button>
                          <button
                            className={styles.anim_right}
                            onClick={() => {
                              const swiper = document.getElementById(`swiper-${i}`).swiper;
                              swiper.slideNext();
                            }}>
                            <svg
                              width='15'
                              height='12'
                              viewBox='0 0 15 12'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <line
                                x1='15'
                                y1='5.85001'
                                x2='-2.18557e-08'
                                y2='5.85'
                                stroke='black'
                                strokeWidth='0.5'
                              />
                              <line
                                y1='-0.25'
                                x2='7.39947'
                                y2='-0.25'
                                transform='matrix(0.675725 -0.737154 0.675725 0.737154 10 12)'
                                stroke='black'
                                strokeWidth='0.5'
                              />
                              <line
                                y1='-0.25'
                                x2='7.39946'
                                y2='-0.25'
                                transform='matrix(-0.675725 -0.737154 0.675725 -0.737154 15 5.45453)'
                                stroke='black'
                                strokeWidth='0.5'
                              />
                            </svg>
                          </button>
                        </div>
                        <div className={styles.__title}>
                          <h1>{item?.title}</h1>
                        </div>
                        <div className={styles.p_detail}>
                          <div>
                            <p>{item?.detail}</p>
                          </div>
                        </div>
                        <div className={styles.price}>
                          <p>{item?.pricePlaceholder}</p>
                        </div>

                        <div className={styles.price}>
                          <p>Pay the 50% upfront deposit:</p>
                        </div>
                        {item?.soldOut ? (
                          <div className={styles.pay__method}>
                            <button disabled>
                              <h2>sold out</h2>
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className={styles.pay__method}>
                              <button
                                onClick={() =>
                                  handleClick(
                                    item?.title,
                                    item?.detail,
                                    item?.price,
                                    item?.processingFee,
                                    'ach'
                                  )
                                }>
                                <h2>bank account</h2>
                              </button>
                              <button
                                onClick={() =>
                                  handleClick(
                                    item?.title,
                                    item?.detail,
                                    item?.price,
                                    item?.processingFee,
                                    'card'
                                  )
                                }>
                                <h2>credit card (+3% fee)</h2>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            )}
          </div>
        ) : (
          <div className={styles.loaderWrapper}>
            <div className={styles.loader} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Products;
