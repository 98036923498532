import React from 'react';
import styles from './hero.module.scss';

const Hero = ({ content }) => (
  <section>
    <div
      style={{ backgroundImage: `url(${content?.heroBG?.asset?.url})` }}
      className={`${styles.hero} ${styles.bg__image}`}>
      <div className={styles.content}>
        <div
          className={styles.logo_D}
          dangerouslySetInnerHTML={{ __html: content?.heroLogoD }}></div>
        <div
          className={styles.logo_T}
          dangerouslySetInnerHTML={{ __html: content?.heroLogoT }}></div>
        <div
          className={styles.logo_M}
          dangerouslySetInnerHTML={{ __html: content?.heroLogoM }}></div>
      </div>
    </div>
  </section>
);

export default Hero;
